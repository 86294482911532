:root {
  --poppins: "Poppins", "Roboto", sans-serif;
  --openSans: "Open Sans", "Roboto", sans-serif;
  --roboto: "Roboto", sans-serif;
  --rochester: "Rochester", sans-serif;
  --quran:  "Noto Naskh Arabic", serif;
  --league: "League Spartan", sans-serif;
  --inter: "inter", sans-serif;
  --leckerli: "Leckerli One", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.breeze_animation {
     animation: flc 4s infinite linear;
     animation-timing-function: linear;
     animation-iteration-count: infinite;
     animation-direction: alternate;
     transform-origin: left top;
   }
.pulse_animation {
  animation: pulse 4s infinite linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: left top;
}
@keyframes flc {
  0% {
    transform: rotate(5deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1.05);
  }
}
@keyframes pulse {
  0%,
  100% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}
